import { Injectable } from '@angular/core';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { environment } from '@src/environments/environment';
import { Profile } from '@models/client/profiles/create-profile-response.model';

@Injectable({ providedIn: 'root' })
export class DatadogService {
  public init(): void {
    if (!environment.production && !environment.staging) {
      return;
    }

    const ddConfig: RumInitConfiguration = {
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.datadog.service,
      env: environment.datadog.env,
      version: environment.datadog.version,
      sessionSampleRate: environment.datadog.sessionSampleRate,
      sessionReplaySampleRate: environment.datadog.sessionReplaySampleRate,
      trackUserInteractions: environment.datadog.trackUserInteractions,
      trackResources: environment.datadog.trackResources,
      trackLongTasks: environment.datadog.trackLongTasks,
      defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel,
      silentMultipleInit: true,
      traceSampleRate: environment.datadog.traceSampleRate,
      telemetrySampleRate: environment.datadog.telemetrySampleRate,
      enableExperimentalFeatures: ['clickmap'],
    };
    datadogRum.init(ddConfig);
    datadogRum.startSessionReplayRecording();
  }

  public setFanInfo(fanInfo: Profile): void {
    if (!environment.production && !environment.staging) {
      return;
    }

    const { mouseliveId, id, firstName, lastName } = fanInfo;
    const socialUser = localStorage.getItem('socialUser');
    datadogRum.setUser({
      mouseliveId: mouseliveId,
      name: `${firstName} ${lastName}`,
      email: socialUser ? JSON.parse(socialUser).email : '',
      type: 'fan',
      id: id,
    });
  }

  public setArtistInfo(artistInfo: Profile): void {
    if (!environment.production && !environment.staging) {
      return;
    }

    const { id, firstName, lastName, email, mouseliveId } = artistInfo;
    const socialUser = localStorage.getItem('socialUser');
    datadogRum.setUser({
      mouseliveId: mouseliveId,
      name: `${firstName} ${lastName}`,
      email: email ? email : socialUser ? JSON.parse(socialUser).email : '',
      type: 'artist',
      id: id,
    });
  }

  public setOrganizerInfo(organizerInfo: Profile): void {
    if (!environment.production && !environment.staging) {
      return;
    }

    const { id, firstName, lastName, email, mouseliveId } = organizerInfo;
    const socialUser = localStorage.getItem('socialUser');
    datadogRum.setUser({
      mouseliveId: mouseliveId,
      name: `${firstName} ${lastName}`,
      email: email ? email : socialUser ? JSON.parse(socialUser).email : '',
      type: 'artist',
      id: id,
    });
  }
}
